import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { ENDPOINTS_STATISTIC } from '../endpoints';
import { getManyVisitsFx } from '../effects';

export const $$getManyVisits = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_STATISTIC.GET_MANY_VISIT.req,
  typeof ENDPOINTS_STATISTIC.GET_MANY_VISIT.res
>({
  initialReq: {
    feedbackIds: [],
    transactionIds: [],
    orgIds: [],
    userIds: [],
    employeeIds: [],
    visitStatuses: ['received'],
    visitIds: [],
    visitUuids: [],
    currentPage: 1,
    itemsPerPage: 100,
  },
  initialFx: getManyVisitsFx,
});
