import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { ENDPOINTS_ADMIN } from '../endpoints';
import { adminGetOneOwnFx } from '../effects';

export const $$adminGetOneOwn = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_ADMIN.ADMIN_GET_ONE_OWN.req,
  typeof ENDPOINTS_ADMIN.ADMIN_GET_ONE_OWN.res
>({
  initialReq: undefined,
  initialFx: adminGetOneOwnFx,
});
