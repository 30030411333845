import { sample } from 'effector';
import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { ENDPOINTS_ADMIN } from '../endpoints';
import { adminSignIn2FaFx, adminSignInFx } from '../effects';

export const $$adminSignIn = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_ADMIN.SIGN_IN.req,
  typeof ENDPOINTS_ADMIN.SIGN_IN.res
>({
  initialReq: { email: '', password: '' },
  initialFx: adminSignInFx,
});

export const $$adminSignIn2FA = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_ADMIN.SIGN_IN_2FA.req,
  typeof ENDPOINTS_ADMIN.SIGN_IN_2FA.res
>({
  initialReq: { code: '45678' },
  initialFx: adminSignIn2FaFx,
});
