import {
  Button,
  Center,
  Flex,
  NavLink,
  Paper,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useUnit } from 'effector-react';
import { $$adminGetOneOwn } from 'entities/admin';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { ChevronRight } from 'react-feather';
import { Navigate } from 'react-router';
import { orgRoleTranslate } from 'shared/lib';

export const MyProfile = () => {
  const { 0: dataAdmin, 1: isLoading } = useUnit([
    $$adminGetOneOwn.$store.map((s) => s.res?.data),
    $$adminGetOneOwn.attachedFx.pending,
  ]);

  const [cookies] = useCookies(['authorized']);

  useEffect(() => {
    $$adminGetOneOwn.argEditEvent();
  }, []);

  if (!cookies.authorized) {
    return <Navigate to='/login' replace />;
  }

  return (
    <Center>
      <Stack w={600} mt={60}>
        <Title ta='center' order={3}>
          Профиль
        </Title>

        <Paper h={400} radius={10} shadow='xs' p='md'>
          <Flex h='100%' direction='column' justify='space-between'>
            <Stack gap={3}>
              <Title order={4}>
                {dataAdmin?.firstname} {dataAdmin?.lastname}
              </Title>

              <Text>{dataAdmin?.email}</Text>
              <Text>Роль: {orgRoleTranslate(dataAdmin?.role)}</Text>
            </Stack>

            <Stack>
              <NavLink
                variant='light'
                active
                href='/change-password'
                label='Сменить пароль'
                rightSection={
                  <ChevronRight size='0.8rem' className='mantine-rotate-rtl' />
                }
              />
              <NavLink
                variant='light'
                active
                href='/my-change-data'
                label='Сменить данные аккаунта'
                rightSection={
                  <ChevronRight size='0.8rem' className='mantine-rotate-rtl' />
                }
              />
            </Stack>
          </Flex>
        </Paper>
      </Stack>
    </Center>
  );
};
