import { Button, Center, Flex, Paper, Stack, Text, Title } from '@mantine/core';
import { useUnit } from 'effector-react';
import { $$adminGetOneOwn, $$adminSignIn } from 'entities/admin/attaches';
import { PasswordChangeForm } from 'features/admin';
import { useEffect } from 'react';

export const ChangePassword = () => {
  const { 0: dataAdmin, 1: isLoading } = useUnit([
    $$adminGetOneOwn.$store.map((s) => s.res?.data),
    $$adminGetOneOwn.attachedFx.pending,
  ]);

  return (
    <Center>
      <Stack gap={40} w={600} mt={60}>
        <Title ta='center' order={3}>
          Сменить пароль
        </Title>
        <PasswordChangeForm />
      </Stack>
    </Center>
  );
};
