import { createApiEffect } from 'entities/effects';
import { ENDPOINTS_STATISTIC } from './endpoints';

export const getManyVisitsFx = createApiEffect<
  typeof ENDPOINTS_STATISTIC.GET_MANY_VISIT.req,
  typeof ENDPOINTS_STATISTIC.GET_MANY_VISIT.res
>({
  service: 'API',
  fxName: false,
  endpoint: ENDPOINTS_STATISTIC.GET_MANY_VISIT,
});

export const getSumBalancesFx = createApiEffect<
  typeof ENDPOINTS_STATISTIC.ADMIN_GET_SUM_BALANCES.req,
  typeof ENDPOINTS_STATISTIC.ADMIN_GET_SUM_BALANCES.res
>({
  service: 'TIPS',
  fxName: false,
  endpoint: ENDPOINTS_STATISTIC.ADMIN_GET_SUM_BALANCES,
});

export const getPaymentMetricsFx = createApiEffect<
  typeof ENDPOINTS_STATISTIC.ADMIN_PAYMENT_METRICS.req,
  typeof ENDPOINTS_STATISTIC.ADMIN_PAYMENT_METRICS.res
>({
  service: 'TIPS',
  fxName: false,
  endpoint: ENDPOINTS_STATISTIC.ADMIN_PAYMENT_METRICS,
});
