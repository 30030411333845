import { Button, Center, Flex, Paper, Stack, Text, Title } from '@mantine/core';
import { useUnit } from 'effector-react';
import { $$adminGetOneOwn, $$adminSignIn } from 'entities/admin/attaches';
import { adminSignInFx } from 'entities/admin/effects';
import { FormCreateModer, SignInForm } from 'features/admin';
import { useEffect } from 'react';

export const CreateModer = () => {
  return (
    <Center>
      <Stack gap={40} w={600} mt={60}>
        <Title ta='center' order={3}>
          Добавьте модератора
        </Title>
        <FormCreateModer />
      </Stack>
    </Center>
  );
};
