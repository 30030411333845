import { createForm } from 'effector-forms';
import { formRules } from 'shared/lib/forms';
import { ENDPOINTS_ADMIN } from '../endpoints';

export const $passwordChangeForm = createForm<
  typeof ENDPOINTS_ADMIN.CHANGE_PASSWORD.req
>({
  validateOn: ['change', 'submit'],
  fields: {
    newPassword: {
      init: '',
      rules: [formRules.required(), formRules.minLength(6)],
    },
    oldPassword: {
      init: '',
      rules: [formRules.required(), formRules.minLength(6)],
    },
  },
});
