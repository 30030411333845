import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { ENDPOINTS_STATISTIC } from '../endpoints';
import { getManyVisitsFx, getSumBalancesFx } from '../effects';

export const $$getSumBalances = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_STATISTIC.ADMIN_GET_SUM_BALANCES.req,
  typeof ENDPOINTS_STATISTIC.ADMIN_GET_SUM_BALANCES.res
>({
  initialReq: undefined,
  initialFx: getSumBalancesFx,
});
