import { T, always, cond, equals } from 'ramda';
export const orgStatusColor = (stays: string | undefined) => {
  return cond([
    [equals('considering'), always('indigo')],
    [equals('canceled'), always('orange')],
    [equals('denied'), always('red')],
    [equals('received'), always('yellow')],
    [equals('removed'), always('red')],
    [equals('approved'), always('green')],
    [T, always('')],
  ])(stays ?? '');
};
