import { Button, Stack } from '@mantine/core';
import { ConnectedField, useForm } from 'effector-forms';
import { $$adminSignIn, $adminSignInForm } from 'entities/admin';

import { FormEvent, useEffect, useState } from 'react';
import { MyPassInput, MyTextInput } from 'shared/ui';
import { useUnit } from 'effector-react';
import { adminSignInFx } from 'entities/admin/effects';

type PropsType = {
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

export function SignInForm({ handleSubmit: onSubmit }: PropsType) {
  const {
    fields,
    submit,
    isDirty,
    values: formValues,
    eachValid,
  } = useForm($adminSignInForm);

  const { 0: isLoadingSignIn, 1: error } = useUnit([
    $$adminSignIn.attachedFx.pending,
    $$adminSignIn.$store.map((s) => s.res?.status),
  ]);

  return (
    <form style={{ flex: 1 }} onSubmit={onSubmit}>
      <Stack gap={17}>
        <MyTextInput
          autoFocus
          label='Введите почту'
          effectorField={fields.email as ConnectedField<string | null>}
        />

        <MyPassInput
          label='Введите пароль'
          effectorField={fields.password as ConnectedField<string>}
        />

        <Button
          disabled={!eachValid || !isDirty}
          loading={isLoadingSignIn}
          size='lg'
          type='submit'
          radius={10}>
          Вход
        </Button>
      </Stack>
    </form>
  );
}
