import { sample } from 'effector';
import { createForm } from 'effector-forms';
import { AdminEntityType } from 'entities/entities-types';
import { appReset } from 'entities/events';

import { isNil } from 'ramda';
import { formRules } from 'shared/lib/forms';

export const $adminSignInForm = createForm<{
  email: string;
  password: string;
}>({
  validateOn: ['blur', 'submit'],
  fields: {
    email: {
      init: '',
      rules: [formRules.required(), formRules.emailIsValid()],
    },
    password: {
      init: '',
      rules: [formRules.required(), formRules.minLength(6)],
    },
  },
});

sample({
  clock: [appReset],
  target: [$adminSignInForm.reset],
});
