import { MantineProvider } from '@mantine/core';
import React, { lazy } from 'react';
import AppRouter from './AppRouter';
import { useCookies } from 'react-cookie';
import { Notifications } from '@mantine/notifications';

const RootProvider = () => {
  return (
    <div className='app'>
      <MantineProvider defaultColorScheme='light'>
        <Notifications />
        <AppRouter />
      </MantineProvider>
    </div>
  );
};

export default RootProvider;
