import { createApiEffect } from 'entities/effects';
import { ENDPOINTS_ORG } from './endpoints';

export const getManyOrgFx = createApiEffect<
  typeof ENDPOINTS_ORG.GET_MANY_ORG.req,
  typeof ENDPOINTS_ORG.GET_MANY_ORG.res
>({
  service: 'API',
  fxName: false,
  endpoint: ENDPOINTS_ORG.GET_MANY_ORG,
});

export const editStatusManyOrgFx = createApiEffect<
  typeof ENDPOINTS_ORG.EDIT_MANY_ORG_STATUS.req,
  typeof ENDPOINTS_ORG.EDIT_MANY_ORG_STATUS.res
>({
  service: 'TIPS',
  fxName: false,
  endpoint: ENDPOINTS_ORG.EDIT_MANY_ORG_STATUS,
});

export const editOrgCommissionFx = createApiEffect<
  typeof ENDPOINTS_ORG.EDIT_ORG_COMMISSION.req,
  typeof ENDPOINTS_ORG.EDIT_ORG_COMMISSION.res
>({
  service: 'TIPS',
  fxName: false,
  endpoint: ENDPOINTS_ORG.EDIT_ORG_COMMISSION,
});
