import { Center, Flex, NumberFormatter, Stack, Title } from '@mantine/core';
import { useUnit } from 'effector-react';
import { $$getSumBalances } from 'entities/statistic';
import { or } from 'ramda';
import { useEffect } from 'react';

export const SumBalances = () => {
  const [sumBalances] = useUnit([
    $$getSumBalances.$store.map((s) => s.res?.data),
  ]);

  useEffect(() => {
    $$getSumBalances.argEditEvent();
  }, []);

  return (
    <Flex w='100%' gap='xl' justify='space-between'>
      <Stack fz={20}>
        <Title order={4}>Баланс Чайника</Title>

        <NumberFormatter
          thousandSeparator=' '
          decimalSeparator=','
          suffix=' ₽'
          value={or(sumBalances?.chainikBalance, 0)}
        />
      </Stack>

      <Stack fz={20}>
        <Title order={4}>Баланс B2P</Title>
        <NumberFormatter
          thousandSeparator=' '
          decimalSeparator=','
          suffix=' ₽'
          value={or(sumBalances?.pspBalance, 0)}
        />
      </Stack>

      <Stack fz={20}>
        <Title order={4}>Разница</Title>

        <NumberFormatter
          thousandSeparator=' '
          decimalSeparator=','
          suffix=' ₽'
          value={or(
            sumBalances &&
              sumBalances?.chainikBalance - sumBalances?.pspBalance,
            0,
          )}
        />
      </Stack>
    </Flex>
  );
};
