import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { ENDPOINTS_STATISTIC } from '../endpoints';
import { getPaymentMetricsFx } from '../effects';

export const $$getPaymentMetrics = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_STATISTIC.ADMIN_PAYMENT_METRICS.req,
  typeof ENDPOINTS_STATISTIC.ADMIN_PAYMENT_METRICS.res
>({
  initialReq: undefined,
  initialFx: getPaymentMetricsFx,
});
