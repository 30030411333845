import { Button, Center, Flex, Paper, Stack, Text, Title } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useUnit } from 'effector-react';
import { StatusValueType } from 'entities/entities-types';
import { $$getManyOrg } from 'entities/org';
import { editStatusManyOrgFx } from 'entities/org';
import { ModalCommission } from 'features/org';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { orgStatusColor, orgStatusTranslate } from 'shared/lib';
import { Condition } from 'shared/ui';

export const OrgProfile = () => {
  const [isClickOpen, setIsClickOpen] = useState(false);
  const { orgId } = useParams();
  useEffect(() => {
    $$getManyOrg.argEditEvent({
      currentPage: 1,
      itemsPerPage: 100,
    });
  }, []);

  const handleButtonClick = (orgId: number, status: StatusValueType) => {
    editStatusManyOrgFx({
      orgs: [{ orgId: orgId, status: status }],
    })
      .then(() =>
        notifications.show({
          autoClose: 2000,
          title: 'Успешно',
          message: 'Статус организации изменен',
          color: 'green',
        }),
      )
      .catch(() =>
        notifications.show({
          autoClose: 2000,
          title: 'Ошибка',
          message: 'Не удалось изменить статус организации',
          color: 'red',
        }),
      );
  };

  const { 0: org, 1: isLoadingEditStatus } = useUnit([
    $$getManyOrg.$store.map((s) =>
      s?.res?.data?.find?.((item) => item.orgId === Number(orgId)),
    ),
    editStatusManyOrgFx.pending,
  ]);

  return (
    <Center>
      <Stack gap={40} w={600} mt={60}>
        <Title ta='center' order={3}>
          Профиль организации
        </Title>
        <Paper h={400} radius={10} shadow='xs' p='md'>
          <Flex h='100%' direction='column' justify='space-between'>
            <Stack>
              <Text fw='bold' fz={18}>
                {org?.name}
              </Text>
              <Text>ИНН: {org?.inn}</Text>
              <Text>{org?.phoneNumber}</Text>
              <Text>{org?.email}</Text>
              <Flex gap={6}>
                <Text>Статус организации:</Text>
                <Text c={orgStatusColor(org?.status)}>
                  {orgStatusTranslate(org?.status)}
                </Text>
              </Flex>
            </Stack>
            <Condition when={org?.status === 'approved'}>
              <Stack>
                <Button
                  variant='light'
                  onClick={() => setIsClickOpen(!isClickOpen)}>
                  Изменить комиссию
                </Button>
              </Stack>
            </Condition>
          </Flex>

          <ModalCommission
            orgId={orgId}
            isClickOpen={isClickOpen}
            setIsClickOpen={setIsClickOpen}
          />
        </Paper>

        <Condition when={org?.status === 'received'}>
          <Button
            loading={isLoadingEditStatus}
            onClick={() => org && handleButtonClick(org?.orgId, 'considering')}
            radius={10}>
            Взять на рассмотрение
          </Button>
        </Condition>

        <Condition when={org?.status === 'considering'}>
          <Stack>
            <Button
              loading={isLoadingEditStatus}
              onClick={() => org && handleButtonClick(org?.orgId, 'approved')}
              radius={10}>
              Одобрить
            </Button>
            <Button
              loading={isLoadingEditStatus}
              color='red'
              onClick={() => org && handleButtonClick(org?.orgId, 'denied')}
              radius={10}>
              Отказать
            </Button>
          </Stack>
        </Condition>
      </Stack>
    </Center>
  );
};
