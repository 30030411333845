import { VisitEntityType } from 'entities/entities-types';
import {
  HttpMethod,
  PaginatedReqType,
  PaginatedResType,
  TipsApiResType,
} from 'shared/api';
import { DataMetrics } from './type';

export const ENDPOINTS_STATISTIC = {
  GET_MANY_VISIT: {
    path: 'visit/get_many',
    method: 'post' as HttpMethod,
    req: {
      feedbackIds: [],
      transactionIds: [],
      orgIds: [],
      userIds: [],
      employeeIds: [],
      visitStatus: ['received'],
      visitIds: [],
      visitUuids: [],
      currentPage: 1,
      itemsPerPage: 100,
    } as PaginatedReqType<{
      feedbackIds?: number[];
      transactionIds?: number[];
      orgIds?: number[];
      userIds?: number[];
      employeeIds?: number[];
      receiverEntities?: ('user' | 'org' | 'employee')[];
      visitStatuses?: ('received' | 'completed' | 'in_progress' | 'failed')[];
      visitIds?: number[];
      visitUuids?: string[];
      createdAtMax?: Date;
      createdAtMin?: Date;
    }>,
    res: {} as TipsApiResType<PaginatedResType<VisitEntityType>>,
  },

  ADMIN_GET_SUM_BALANCES: {
    path: 'admin/get_diff_our_balances',
    method: 'get' as HttpMethod,
    req: undefined as undefined,
    res: {} as TipsApiResType<{ chainikBalance: number; pspBalance: number }>,
  },

  ADMIN_PAYMENT_METRICS: {
    path: 'admin/payment_metrics',
    method: 'post' as HttpMethod,
    req: undefined as undefined,
    res: {} as TipsApiResType<DataMetrics>,
  },
};
