import {
  CloseButton,
  Flex,
  NumberInput,
  NumberInputProps,
  Stack,
} from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { ConnectedField } from 'effector-forms';
import { useRef } from 'react';
import { Condition } from 'shared/ui/display';

import { InputAutocompleteType } from './types';

type PropsType = NumberInputProps & {
  effectorField?: ConnectedField<number | null>;
  autoComplete?: InputAutocompleteType;
};
export default function MyNumberInput(props: PropsType) {
  const { autoComplete, leftSection, error, effectorField, ...propsForInput } =
    props;
  const { ref, height } = useElementSize();
  const gap = 5;

  const refInput = useRef<HTMLInputElement>(null);
  let resultProps = propsForInput;
  if (effectorField)
    resultProps = {
      ...propsForInput,
      value: effectorField.value || '',
      onChange: (e) => effectorField?.onChange(Number(e) || null),
      onBlur: () => effectorField?.onBlur(),
    };

  const resultErrorText = effectorField?.errorText();

  return (
    <Stack pos='relative' gap={gap}>
      <Flex ref={ref}>
        <NumberInput
          ref={refInput}
          {...resultProps}
          flex={1}
          error={Boolean(resultErrorText)}
          rightSection={
            <Condition
              when={Boolean(effectorField) && Boolean(effectorField?.value)}>
              <CloseButton onClick={() => effectorField?.reset()} />
            </Condition>
          }
        />
      </Flex>
    </Stack>
  );
}
