import { Center, Stack, Text, Title } from '@mantine/core';

import '@mantine/charts/styles.css';

import { Metrics, SumBalances } from 'widgets/statistic';

export const Statistic = () => {
  return (
    <Center>
      <Stack w={1000} mt='xl' align='center'>
        <Title order={2}>Статистика Чайника</Title>

        <SumBalances />
        <Metrics />
      </Stack>
    </Center>
  );
};
