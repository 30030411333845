import { Button, Loader, Stack } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { ConnectedField, useForm } from 'effector-forms';
import { useUnit } from 'effector-react';
import { $passwordChangeForm } from 'entities/admin';
import { changePasswordFx } from 'entities/admin';
import { FormEvent } from 'react';
import { MyPassInput } from 'shared/ui';

type PropsType = {
  onSuccess?: () => void;
};

export function PasswordChangeForm({ onSuccess }: PropsType) {
  const { fields, submit, errorText, isDirty, values, eachValid, reset } =
    useForm($passwordChangeForm);

  const { 0: isLoading } = useUnit([changePasswordFx.pending]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!eachValid) return;
    changePasswordFx(values)
      .then(() => {
        if (onSuccess) onSuccess();
      })
      .then(() => {
        notifications.show({
          autoClose: 2000,
          title: 'Успешно',
          message: 'Пароль был успешно изменен',
          color: 'green',
        });
      })
      .catch(() =>
        notifications.show({
          autoClose: 2000,
          title: 'Ошибка',
          message: 'Не удалось сменить пароль',
          color: 'red',
        }),
      );
    submit();
    reset();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap={17}>
        <MyPassInput
          label='Введите старый пароль'
          effectorField={fields.oldPassword as ConnectedField<string>}
        />

        <MyPassInput
          label='Введите новый пароль'
          effectorField={fields.newPassword as ConnectedField<string>}
        />

        <Button loading={isLoading} disabled={!isDirty} type='submit'>
          Отправить
        </Button>
      </Stack>
    </form>
  );
}
