import {
  Flex,
  NumberFormatter,
  Progress,
  Stack,
  Text,
  Tooltip,
  Card,
} from '@mantine/core';

import { PaymentMetrics } from 'entities/statistic/type';
import { or } from 'ramda';

export const CardMetrics = ({ item }: { item: PaymentMetrics | undefined }) => {
  return (
    <Card shadow='sm' miw={250} radius='md' withBorder>
      <Stack>
        <Progress.Root radius='md' size={20}>
          <Tooltip label={`${item?.percentSuccessfulPayments}%`} withArrow>
            <Progress.Section
              value={or(item?.percentSuccessfulPayments, 0)}
              color='cyan'>
              <Progress.Label fw={500} fz='xs'>
                Успешные
              </Progress.Label>
            </Progress.Section>
          </Tooltip>
          <Tooltip label={`${item?.percentUnsuccessfulPayments}%`} withArrow>
            <Progress.Section
              value={or(item?.percentUnsuccessfulPayments, 0)}
              color='red'>
              <Progress.Label fw={500} fz='xs'>
                Неудачные
              </Progress.Label>
            </Progress.Section>
          </Tooltip>
        </Progress.Root>
        <Stack>
          <Flex gap={3} justify='space-between'>
            <Text c='cyan'>Успешные:</Text>
            <Text>{item?.successfulPayments} шт</Text>
          </Flex>
          <Flex gap={3} justify='space-between'>
            <Text c='red'>Неудачные:</Text>
            <Text>{item?.unsuccessfulPayments} шт.</Text>
          </Flex>
        </Stack>
        <Stack>
          <Flex gap={3} justify='space-between'>
            <Text>Количество платежей:</Text>
            <Text>{item?.numberOfPayments} шт.</Text>
          </Flex>
          <Flex gap={3} justify='space-between'>
            <Text>Сумма платежей:</Text>
            <NumberFormatter
              thousandSeparator=' '
              decimalSeparator=','
              suffix=' ₽'
              value={item?.paymentAmount}
            />
          </Flex>
        </Stack>
        <Stack>
          <Flex gap={3} fw='bold' justify='space-between'>
            <Text fw='bold'>Наш доход:</Text>
            <NumberFormatter
              thousandSeparator=' '
              decimalSeparator=','
              suffix=' ₽'
              value={item?.revenue}
            />
          </Flex>
        </Stack>
      </Stack>
    </Card>
  );
  // };
};
