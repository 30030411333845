import { Rule } from 'effector-forms';

export const formRules = {
  required: (): Rule<string | number | null> => ({
    name: 'required',
    validator: (value) => Boolean(value),
    errorText: 'Поле не может быть пустым',
  }),
  innIsValid: (): Rule<string | null> => ({
    name: 'inn',
    validator: (value) => {
      if (!value) return true;
      return /^\d{10}$/.test(value);
    },
    errorText: 'ошибка',
  }),
  minLength: (min: number): Rule<string> => ({
    name: 'minLength',
    validator: (value) => value.length >= min,
    errorText: `Длина не менее ${min} символов`,
  }),
  maxLength: (max: number): Rule<string> => ({
    name: 'maxLength',
    validator: (value) => value.length <= max,
    errorText: `Длина не более ${max} символов`,
  }),
  emailIsValid: (): Rule<string | null> => ({
    name: 'emailIsValid',
    validator: (value) => {
      if (!value) return true;
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value,
      );
    },
    errorText: 'Данные введены неверно',
  }),
  phoneIsValid: (): Rule<string | null> => ({
    name: 'phoneIsValid',
    validator: (value) => {
      if (!value) return true;
      return /^\+?[1-9]\d{10}$/.test(value);
    },
    errorText: 'Данные введены неверно',
  }),

  imageFormat: (): Rule<FileList | string | null> => ({
    name: 'imageFormat',
    validator: (value, form, source) => {
      if (!value) return true;
      return (
        value instanceof FileList &&
        value?.[0] &&
        ['image/jpeg', 'image/png', 'image/gif'].includes(value[0].type)
      );
    },
    errorText: 'Тип файла должен быть одним из: .jpeg, .png, .gif',
  }),
  imageSize: (): Rule<FileList | string | null> => ({
    name: 'imageSize',
    validator: (value, form, source) => {
      if (!value) return true;
      return (
        value instanceof FileList && value?.[0] && value[0].size > 5_000_000
      );
    },
    errorText: 'Размер файла не должен превышать 5 MB',
  }),
  maxSum: (max: number): Rule<number | null> => ({
    name: 'maxSum',
    validator: (value) => Number(value) <= max,
    errorText: `Максимальная сумма для вывода ${max.toLocaleString('ru-RU')} ₽`,
  }),
  minSum: (min: number | null, errorText: string): Rule<number | null> => ({
    name: 'minSum',

    validator: (value) => {
      return min !== null && Number(value) >= min;
    },
    errorText: `${errorText} ${min ? min.toLocaleString('ru-RU') : '0'} ₽`,
  }),
};
