import { Button, Center, Flex, Paper, Stack, Text, Title } from '@mantine/core';
import { useUnit } from 'effector-react';
import { $$adminGetOneOwn } from 'entities/admin/attaches';
import { DataChangeForm } from 'features/admin';
import { useEffect } from 'react';

export const MyChangeData = () => {
  return (
    <Center>
      <Stack gap={40} w={600} mt={60}>
        <Title ta='center' order={3}>
          Сменить данные
        </Title>
        <DataChangeForm />
      </Stack>
    </Center>
  );
};
