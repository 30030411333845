import { DefaultMantineColor } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
  Effect,
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from 'effector';
import { HttpMethod, TipsApiResType } from 'shared/api';
import { request } from 'shared/api/base-api';
import { responseProcessingKy } from 'shared/api/common';

type RequestType = 'TIPS' | 'API';
type CreateApiEffectParams = {
  service: RequestType;
  endpoint: {
    method: HttpMethod;
    path: string;
    res: any;
    req: any;
  };
  fxName: string | false; // if provided, notification handler is included
};
type NotificationStatusType = 'success' | 'error' | 'info';
export type AppNotifyPropsType = {
  status?: NotificationStatusType;
  toastTitle: string;
  toastMessage: string;
  autoClose?: number;
};

// const withErrorNotification = <T, U>(
//   effect: Effect<T, U, TipsApiResType<void>>,
//   effectName?: string,
// ) => {
//   sample({
//     clock: effect.failData,
//     source: $translate,
//     fn: (t, err) => {
//       const status = String(err.status);
//       const defaultTitle = t('SERVER_ACTIONS.common.title');

//       const defaultMessage =
//         // @ts-ignore
//         t(`SERVER_ACTIONS.common.status.${status}`) ||
//         t(`SERVER_ACTIONS.common.status.500`);
//       // @ts-ignore
//       const customTitle = t(`SERVER_ACTIONS.${effectName}.title`);
//       // @ts-ignore
//       const customMessage = t(`SERVER_ACTIONS.${effectName}.status.${status}`);

//       return {
//         status: 'error',
//         toastTitle: customTitle || defaultTitle,
//         toastMessage: customMessage || defaultMessage,
//         autoClose: 5000,
//       } as AppNotifyPropsType;
//     },
//     target: appNotifyFx,
//   });

//   return effect;
// };

// const withSuccessNotification = <T, U>(
//   effect: Effect<T, U, TipsApiResType<void>>,
//   effectName?: string,
// ) => {
//   sample({
//     clock: effect.doneData,
//     source: $translate,
//     fn: (t, data) => {
//       const defaultTitle = 'Ответ';

//       const defaultMessage = 'Успех';
//       // @ts-ignore
//       const customTitle = 'Ответ';
//       const customMessage = 'Успешно';

//       return {
//         status: 'success',
//         toastTitle: customTitle || defaultTitle,
//         toastMessage: customMessage || defaultMessage,
//         autoClose: 5000,
//       } as AppNotifyPropsType;
//     },
//     target: appNotifyFx,
//   });

//   return effect;
// };

export const createApiEffect = <Req, Res>({
  endpoint,
  service,
  fxName, // if provided, notification handler is included
}: CreateApiEffectParams) => {
  const effect = createEffect<Req, Res, TipsApiResType<void>>((req: Req) =>
    responseProcessingKy<Res>(() =>
      request(service)[endpoint.method](endpoint.path, { json: req }),
    ),
  );
  if (fxName) {
    // withErrorNotification(effect, fxName);
  }
  return effect;
};

export const appNotifyFx = createEffect(
  ({ status, autoClose, toastTitle, toastMessage }: AppNotifyPropsType) => {
    if (Boolean(toastMessage) && Boolean(toastTitle)) return undefined;
    console.log('хуй');

    let color: DefaultMantineColor = 'blue';
    if (status === 'error') color = 'red';
    if (status === 'success') color = 'green';
    showNotification({
      color: color,
      title: toastTitle,
      message: toastMessage,
      autoClose: autoClose ?? 5000,
      withBorder: true,
    });
  },
);

export function createStoreAndEventAndAttachForEffect<REQ, RES>({
  initialReq,
  initialFx,
}: {
  initialReq: REQ;
  initialFx: Effect<REQ, RES, TipsApiResType<void>>;
}) {
  const $store = createStore<{
    req: REQ;
    res: RES | null;
  }>({
    req: initialReq,
    res: null,
  });
  const attachedFx = attach({
    source: $store.map((state) => state.req),
    effect: initialFx,
  });
  const argResetEvent = createEvent();
  const argEditEvent = createEvent<REQ>();
  $store
    .on(attachedFx.doneData, (state, payload) => {
      return { ...state, res: payload };
    })
    .on(attachedFx.fail, (state, payload) => {
      return { ...state, res: { status: payload.error.status } as RES };
    })
    .on(argEditEvent, (state, payload) => {
      return { ...state, req: payload };
    })
    .reset(argResetEvent);
  sample({ clock: argEditEvent, target: attachedFx });
  return { $store, argEditEvent, argResetEvent, attachedFx };
}
