import ky from 'ky';
import { or } from 'ramda';

type RequestType = 'TIPS' | 'API';

export const request = (service: RequestType) => {
  const prefixUrlMap = {
    // TIPS: window.REACT_APP_ADMIN_API_URL,
    // API: window.REACT_APP_BASE_TIPS_URL,
    TIPS: 'https://api.chainik.fun',
    API: 'https://tips.chainik.fun',
    // TIPS: 'https://api.chainik.pro',
    // API: 'https://tips.chainik.pro',
    // TIPS: '/admin',
    // API: '/tips',
  };

  return ky.create({
    credentials: 'include',
    prefixUrl: prefixUrlMap[service],
    throwHttpErrors: false,
    timeout: 30000,
  });
};
