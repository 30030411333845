import {
  AdminEntityType,
  ModeratorEntityType,
  OrgEntityType,
} from 'entities/entities-types';
import {
  HttpMethod,
  PaginatedReqType,
  PaginatedResType,
  TipsApiResType,
} from 'shared/api';

export const ENDPOINTS_ADMIN = {
  SIGN_IN: {
    path: 'admin/sign_in',
    method: 'post' as HttpMethod,
    req: {
      email: '89163685055',
      password: '666666',
    } as Partial<Pick<AdminEntityType, 'password' | 'email'>>,
    res: {} as TipsApiResType<void>,
  },

  ADMIN_GET_ONE_OWN: {
    path: 'admin/get_one_own',
    method: 'post' as HttpMethod,
    req: undefined as undefined,
    res: {} as TipsApiResType<AdminEntityType>,
  },

  SIGN_IN_2FA: {
    path: 'admin/sign_in_2FA',
    method: 'post' as HttpMethod,
    req: {
      code: '776594',
    } as { code: string },
    res: {} as TipsApiResType<void>,
  },

  CREATE_MODER_ONE: {
    path: 'admin/create_one',
    method: 'post' as HttpMethod,
    req: {
      password: 'penisassss',
      email: 'bigpenis@gmail.com',
      role: 'moder',
      firstname: '12',
      lastname: '',
      patronymic: '',
    } as Pick<
      ModeratorEntityType,
      'password' | 'email' | 'role' | 'firstname' | 'lastname' | 'patronymic'
    >,
    res: {} as TipsApiResType<void>,
  },

  CHANGE_PASSWORD: {
    path: 'admin/change_password',
    method: 'patch' as HttpMethod,
    req: {
      oldPassword: '1234567',
      newPassword: '666666',
    } as { oldPassword: string; newPassword: string },
    res: {} as TipsApiResType<void>,
  },

  CHANGE_DATA: {
    path: 'admin/change_data',
    method: 'patch' as HttpMethod,
    req: {
      email: 'bigpenis@gmail.com',
      firstname: '12',
      lastname: '',
      patronymic: '',
    } as Pick<
      AdminEntityType,
      'email' | 'firstname' | 'lastname' | 'patronymic'
    >,
    res: {} as TipsApiResType<void>,
  },

  SIGN_OUT: {
    path: 'admin/sign_out',
    method: 'delete' as HttpMethod,
    req: undefined as void,
    res: {} as TipsApiResType<void>,
  },

  // SIGN_UP: {
  //   path: 'user/sign_up',
  //   method: 'post' as HttpMethod,
  //   req: {
  //     firstname: 'Никита',
  //     lastname: 'Путинцев',
  //     patronymic: 'Евгеньевич',
  //     phoneNumber: '89163685055',
  //     password: '666666',
  //     email: '89163685055@list.ru',
  //   } as Omit<UserEntityType, 'userId'>,
  //   res: {} as TipsApiResType<void>,
  //   err: {
  //     '407': 'asdfasf', // возвращается при номер телефона уже занят
  //   },
  // },

  // // при создании пользователя создаётся ORG со статусом 'proposed', создаётся EMPLOYEE с ролью 'owner'
  // SIGN_UP_WITH_ORG_APPLICATION: {
  //   path: 'user/sign_up_user_and_organization',
  //   method: 'post' as HttpMethod,
  //   req: {
  //     user: {
  //       firstname: 'Никита',
  //       lastname: 'Путинцев',
  //       patronymic: 'Евгеньевич',
  //       phoneNumber: '89163685055',
  //       password: '666666',
  //       email: '89163685055@list.ru',
  //     },
  //     org: {
  //       name: 'Макдак',
  //       inn: '1234567890',
  //     },
  //   } as {
  //     user: Omit<UserEntityType, 'userId'>;
  //     org: Omit<OrgEntityType, 'orgId' | 'status'>;
  //   },
  //   res: {} as TipsApiResType<void>,
  // },
};
