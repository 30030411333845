import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { ENDPOINTS_ORG } from '../endpoints';
import { getManyOrgFx } from '../effects';

export const $$getManyOrg = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_ORG.GET_MANY_ORG.req,
  typeof ENDPOINTS_ORG.GET_MANY_ORG.res
>({
  initialReq: {
    currentPage: 1,
    itemsPerPage: 100,
  },
  initialFx: getManyOrgFx,
});
