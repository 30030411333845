import { sample } from 'effector';
import { createForm } from 'effector-forms';
import { AdminEntityType } from 'entities/entities-types';
import { appReset } from 'entities/events';

import { isNil } from 'ramda';
import { formRules } from 'shared/lib/forms';
import { ENDPOINTS_ADMIN } from '../endpoints';

export const $addModerForm = createForm<
  typeof ENDPOINTS_ADMIN.CREATE_MODER_ONE.req
>({
  validateOn: ['blur', 'submit'],
  fields: {
    email: {
      init: '',
      rules: [formRules.required(), formRules.emailIsValid()],
    },
    role: {
      init: 'moder',
      rules: [formRules.required()],
    },
    lastname: {
      init: '',
      rules: [formRules.required(), formRules.maxLength(30)],
    },
    firstname: {
      init: '',
      rules: [formRules.required(), formRules.maxLength(30)],
    },
    patronymic: {
      init: '',
      rules: [formRules.maxLength(30)],
    },
    password: {
      init: '',
      rules: [
        formRules.required(),
        formRules.maxLength(30),
        formRules.minLength(6),
      ],
    },
  },
});

sample({
  clock: [appReset],
  target: [$addModerForm.reset],
});
