import { createForm } from 'effector-forms';
import { formRules } from 'shared/lib/forms';
import { ENDPOINTS_ADMIN } from '../endpoints';
import { $$adminGetOneOwn } from '../attaches';
import { sample } from 'effector';
import { AdminEntityType } from 'entities/entities-types';
import { appReset } from 'entities/events';
export const $dataChangeForm = createForm<
  typeof ENDPOINTS_ADMIN.CHANGE_DATA.req
>({
  validateOn: ['change', 'submit'],
  fields: {
    email: {
      init: '',
      rules: [formRules.emailIsValid()],
    },
    lastname: {
      init: '',
      rules: [formRules.maxLength(30)],
    },
    firstname: {
      init: '',
      rules: [formRules.maxLength(30)],
    },
    patronymic: {
      init: '',
      rules: [formRules.maxLength(30)],
    },
  },
});

sample({
  clock: $$adminGetOneOwn.$store.map((s) => s.res?.data),
  fn: (clockData) => ({
    email: clockData?.email ?? '',
    firstname: clockData?.firstname ?? '',
    lastname: clockData?.lastname ?? '',
    patronymic: clockData?.patronymic ?? '',
  }),
  target: $dataChangeForm.setForm,
});

sample({
  clock: [appReset],
  target: [$dataChangeForm.reset],
});
