import { Button, Loader, Stack } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { ConnectedField, useForm } from 'effector-forms';
import { useUnit } from 'effector-react';
import {
  $dataChangeForm,
  $passwordChangeForm,
  changeDataFx,
} from 'entities/admin';
import { changePasswordFx } from 'entities/admin';
import { FormEvent } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { MyPassInput, MyTextInput } from 'shared/ui';

type PropsType = {
  onSuccess?: () => void;
};

export function DataChangeForm({ onSuccess }: PropsType) {
  const { fields, submit, errorText, isDirty, values, eachValid, reset } =
    useForm($dataChangeForm);

  const { 0: isLoading } = useUnit([changeDataFx.pending]);
  const navigate = useNavigate();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!eachValid) return;
    changeDataFx(values)
      .then(() => {
        navigate('/my-profile');
        if (onSuccess) onSuccess();
      })
      .then(() =>
        notifications.show({
          autoClose: 2000,
          title: 'Успешно',
          message: 'Данные были успешно изменены',
          color: 'green',
        }),
      )
      .catch(() =>
        notifications.show({
          autoClose: 2000,
          title: 'Ошибка',
          message: 'Не удалось сменить данные',
          color: 'red',
        }),
      );
    submit();
    reset();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap={17}>
        <MyTextInput
          autoComplete='family-name'
          label='Имя'
          effectorField={fields.firstname as ConnectedField<string | null>}
        />

        <MyTextInput
          autoComplete='given-name'
          label='Фамилия'
          effectorField={fields.lastname as ConnectedField<string | null>}
        />
        <MyTextInput
          autoComplete='additional-name'
          label='Отчество'
          effectorField={fields.patronymic as ConnectedField<string | null>}
        />

        <MyTextInput
          autoComplete='email'
          label='Почта'
          effectorField={fields.email as ConnectedField<string | null>}
        />
        <Button loading={isLoading} disabled={!isDirty} type='submit'>
          Отправить
        </Button>
      </Stack>
    </form>
  );
}
