import { Navigate, Route, Routes } from 'react-router-dom';
import { PageLayout } from './page-layout';
import { CreateModer } from 'pages/ui/create-moder';
import { Organizations } from 'pages/ui/organizations';
import { OrgProfile } from 'pages/ui/org-profile';
import { MyProfile } from 'pages/ui/my-profile';
import { ChangePassword } from 'pages/ui/my-change-password';
import { MyChangeData } from 'pages/ui/my-change-data';
import { useCookies } from 'react-cookie';
import { LoginPage } from 'pages/ui/login';
import { $$adminGetOneOwn } from 'entities/admin';
import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import { Statistic } from 'pages/ui/statistic';

const routes = [
  // { path: '/create-moder', element: <CreateModer /> },
  { path: '/organizations', element: <Organizations /> },
  { path: '/profile-org/:orgId', element: <OrgProfile /> },
  { path: '/change-password', element: <ChangePassword /> },
  { path: '/my-change-data', element: <MyChangeData /> },
  { path: '/statistic', element: <Statistic /> },
];

const AppRouter = () => {
  const [cookies] = useCookies(['authorized']);
  const isAuthorized = !!cookies.authorized;

  useEffect(() => {
    $$adminGetOneOwn.argEditEvent();
  }, []);

  const { 0: dataAdmin } = useUnit([
    $$adminGetOneOwn.$store.map((s) => s.res?.data),
  ]);

  return (
    <Routes>
      <Route
        path='/'
        element={
          isAuthorized ? (
            <Navigate to='/my-profile' replace />
          ) : (
            <Navigate to='/login' replace />
          )
        }
      />
      <Route
        path='/login'
        element={
          <PageLayout>
            <LoginPage />
          </PageLayout>
        }
      />
      <Route
        path='/my-profile'
        element={
          <PageLayout>
            <MyProfile />
          </PageLayout>
        }
      />
      {dataAdmin?.role === 'admin' && (
        <Route
          path='/create-moder'
          element={
            isAuthorized ? (
              <PageLayout>
                <CreateModer />
              </PageLayout>
            ) : (
              <Navigate to='/login' replace />
            )
          }
        />
      )}
      {routes.map(({ path, element }) => (
        <Route
          key={path}
          path={path}
          element={
            isAuthorized ? (
              <PageLayout>{element}</PageLayout>
            ) : (
              <Navigate to='/login' replace />
            )
          }
        />
      ))}
      {/* <Route path='*' element={<Navigate to='/' replace />} />{' '} */}
    </Routes>
  );
};

export default AppRouter;
