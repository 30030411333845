import { Button, Stack } from '@mantine/core';
import { ConnectedField, useForm } from 'effector-forms';
import { $addModerForm, createModerOneFx } from 'entities/admin';
import { FormEvent, useEffect, useState } from 'react';
import { MyPassInput, MyTextInput } from 'shared/ui';
import { useUnit } from 'effector-react';
import { notifications } from '@mantine/notifications';

type PropsType = {
  onSuccess?: () => void;
};

export function FormCreateModer({ onSuccess }: PropsType) {
  const {
    fields,
    submit,
    reset,
    isDirty,
    values: formValues,
    eachValid,
  } = useForm($addModerForm);

  const { 0: isLoading } = useUnit([createModerOneFx.pending]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submit();
    createModerOneFx({
      password: formValues.password,
      email: formValues.email,
      role: 'moder',
      firstname: formValues.firstname,
      lastname: formValues.lastname,
      patronymic: formValues.patronymic,
    })
      .then(() =>
        notifications.show({
          autoClose: 2000,
          title: 'Успешно',
          message: 'Модератор добавлен',
          color: 'green',
        }),
      )
      .catch(() =>
        notifications.show({
          autoClose: 2000,
          title: 'Ошибка',
          message: 'Не удалось создать модератора',
          color: 'red',
        }),
      );
    reset();
  };

  return (
    <Stack mt={30}>
      <form style={{ flex: 1 }} onSubmit={handleSubmit}>
        <Stack gap={17}>
          <MyTextInput
            label='Имя'
            effectorField={fields.firstname as ConnectedField<string | null>}
          />

          <MyTextInput
            label='Фамилия'
            effectorField={fields.lastname as ConnectedField<string | null>}
          />
          <MyTextInput
            label='Отчество'
            effectorField={fields.patronymic as ConnectedField<string | null>}
          />

          <MyTextInput
            label='Почта'
            effectorField={fields.email as ConnectedField<string | null>}
          />

          <MyPassInput
            label='Пароль'
            effectorField={fields.password as ConnectedField<string>}
          />

          <Button
            disabled={!eachValid || !isDirty}
            loading={isLoading}
            size='lg'
            type='submit'
            radius={10}>
            Добавить
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}
