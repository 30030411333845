import {
  AdminEntityType,
  ModeratorEntityType,
  OrgEntityType,
  StatusValueType,
} from 'entities/entities-types';
import {
  HttpMethod,
  PaginatedReqType,
  PaginatedResType,
  TipsApiResType,
} from 'shared/api';

export const ENDPOINTS_ORG = {
  GET_MANY_ORG: {
    path: 'org/get_many',
    method: 'post' as HttpMethod,
    req: {
      currentPage: 1,
      itemsPerPage: 3,
    } as PaginatedReqType<{}>,
    res: {} as TipsApiResType<OrgEntityType[]>,
  },

  EDIT_MANY_ORG_STATUS: {
    path: 'admin/org/update_many',
    method: 'patch' as HttpMethod,
    req: {} as { orgs: [{ orgId: number; status: StatusValueType }] },
    res: {} as TipsApiResType<void>,
  },

  EDIT_ORG_COMMISSION: {
    path: 'admin/commission/update_one',
    method: 'patch' as HttpMethod,
    req: {
      orgId: 2,
      paymentCommissionInPercent: 7,
      withdrawCommissionInScalar: 30,
    } as {
      orgId: number;
      paymentCommissionInPercent: number;
      withdrawCommissionInScalar: number;
    },
    res: {} as TipsApiResType<void>,
  },
};
