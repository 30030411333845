import { sample } from 'effector';
import { createForm } from 'effector-forms';
import { appReset } from 'entities/events';

export const $formEditCommission = createForm<{
  paymentCommissionInPercent: number | null;
  withdrawCommissionInScalar: number | null;
}>({
  validateOn: ['blur', 'submit'],
  fields: {
    paymentCommissionInPercent: {
      init: 7,
      rules: [],
    },
    withdrawCommissionInScalar: {
      init: 30,
      rules: [],
    },
  },
});

sample({
  clock: [appReset],
  target: [$formEditCommission.reset],
});
